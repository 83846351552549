export default [
  // {
  //   title: 'Dashboard',
  //   route: 'home',
  //   icon: 'HomeIcon',
  //   action: 'read',
  //   resource: 'home',
  // },
  {
    title: 'Minha empresa',
    route: 'contracts-by-company',
    icon: 'FileIcon',
    action: 'read',
    resource: 'MY-COMPANY',
  }, 
  {
    title: 'Escolas',
    route: 'schools',
    icon: 'GridIcon',
    action: 'read',
    resource: 'SCHOOL_ADMIN',
  },
]
